<template>
  <div>
    <!-- footer section starts  -->

    <section class="footer">
      <div class="links">
        <a class="btn" href="#home">Accueil</a>
        <a class="btn" href="#about">A propos</a>
        <a class="btn" href="#services">Prestations</a>
        <!-- <a class="btn" href="#projects">projects</a>
        <a class="btn" href="#pricing">pricing</a> -->
        <a class="btn" href="#contact">Contact</a>
        <!-- <a class="btn" href="#blogs">blogs</a> -->
      </div>

      <div class="credit">
        ASK <span> Gras Savoye Bénin </span> | tous droits reservés! |
        <a href="#">Mentions Légales</a> |
        <!-- <a href="#">politiques de confidentialité</a> -->
        <router-link to="/politiques"
          >politiques de confidentialité</router-link
        >
      </div>
    </section>

    <!-- footer section ends -->
  </div>
</template>
<script>
export default {};
</script>
